import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import HeaderNav from '../HeaderNav';
import './Header.scss';
import GatsbyLink from '../GatsbyLink'
import { Image } from '../Image'
import { RenderShortcode } from '../RenderShortcode'
import { ProductImage } from '../Products/ProductImage'
import RenderContent from '../RenderContent'
const isClient = typeof window !== 'undefined';
import { decodeEntities } from '../../utils/helpers';
import { Logo } from '../../../theme/Logo';

class Header extends Component {
  state = {
    contactActive: false,
    navActive: false,
    tabActive: false,
    tabActiveColour: false,
    productTabs: 0,
    marketTabs: 0,
    contactTabs: 0
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  /* Small function to remove the nav-open
   * On a window resize.
   */
  handleResize = () => {
    if (window.innerWidth > 1080) {
      if (isClient) {
        let childrenDomElements = document.querySelectorAll('.children');
        for (let i = 0; i < childrenDomElements.length; i++) {
          if (childrenDomElements[i].classList.contains('open')) {
            childrenDomElements[i].classList.remove('open')
          }
        }
        if (document.body.classList.contains('nav-open')) {
          this.toggleNav();
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  toggleTab = (event, tab) => {
    const { tabActive } = this.state
    if (event) event.preventDefault();
    if (tabActive === tab) {
      //Close
      this.setState({ tabActive: false });

    } else {
      this.setState({ tabActive: tab, tabActiveColour: tab })
    }
  }

  mobileToggleTab = (event, tab) => {
    const { tabActive } = this.state
    if (event) event.preventDefault();
    //if (tabActive === tab) {
    //Close
    // this.setState({ tabActive: false });

    //} else {
    this.setState({ tabActive: tabActive === tab ? 'none' : tab, tabActiveColour: tab })
    //}
  }

  closeTabs = (event) => {
    this.setState({ tabActive: false });
    if (isClient) {
      document.body.classList.remove('nav-open');
    }
  }

  toggleSubTab = (group, index) => {
    this.setState({ [group]: index });
  }

  toggleNav = event => {
    const { navActive } = this.state;
    if (event) event.preventDefault();
    if (!navActive) {
      if (isClient) {
        document.body.classList.add('nav-open');
      }
      this.setState({
        contactActive: false,
        navActive: true,
      });
    } else {
      if (isClient) {
        document.body.classList.remove('nav-open');
      }
      this.setState({ navActive: false });
    }
  };

  closeAll = event => {
    if (event) event.preventDefault();
    if (isClient) {
      document.body.classList.remove('nav-open');
    }
    this.setState({
      navActive: false,
      tabActive: false,
    });
  };

  render() {
    const {
      navActive,
      tabActive,
      tabActiveColour,
      marketTabs,
      productTabs,
      contactTabs
    } = this.state;
    const {
      data,
    } = this.props;
    const {
      primaryNavigation,
      allWordpressWpProductMarket,
      allWordpressWpProductCategory,
      allWordpressWpProducts,
      siteSettings,
      wordpressWpSettings
    } = data;
    const { options } = siteSettings
    const { locations, menu_image, contact_tab_content, contact_tab_image, request_a_sample_content, request_a_sample_image } = options
    const { baseUrl } = wordpressWpSettings;
    const { items: navItems } = primaryNavigation
    const tabItems = ['menu', 'markets', 'products', 'contact'];
    const contactTabsItems = ['Enquiry', 'Request a sample'];

    const productsInThiscategory = (categoryId) => {
      const returnThis = allWordpressWpProducts.nodes.filter(post => !post.slug.includes('gatsby') && post.product_category.find(category => category.wordpress_id === categoryId))
      return returnThis ? returnThis : []
    }
    return (

      <header className={`header${tabActive ? ' open' : ''}`} id="header">
        <div className="mobile-menu-head">
          <div className="logo"><Logo /></div>
          <button
            className={`trigger-main`}
            onClick={event => this.toggleTab(event, tabActive === false ? 'menu' : tabActive)}
          ><div className={`burger${tabActive ? ' active' : ''}`}><span className="inner" /></div></button>
        </div>

        <div className="tab-nav">
          <div className="desktop-triggers">
            <button
              className={`trigger-main${tabActive ? ' active' : ''}`}
              onClick={event => this.toggleTab(event, tabActive === false ? 'menu' : tabActive)}
            >
              <div className={`burger${tabActive ? ' active' : ''}`}><span className="inner" /></div>
            </button>
            {tabItems.map((tab, index) => {
              const activeTab = tabActive === tab ? ' active' : ''
              return (
                <button key={index} className={`trigger ${tab}${activeTab}`} onClick={event => this.toggleTab(event, tab)}>
                  <span className="text">{tab}</span>
                  {tab !== 'menu' && <div className={`burger${activeTab}`}><span className="inner" /></div>}
                </button>
              )
            })}
          </div>

          <div className={`tabs${tabActiveColour ? ` ${tabActiveColour}` : ''}`}>

            <div className={`tab menu${tabActive === 'menu' ? ' active' : ''}`}>
              <button className="mobile-trigger menu" onClick={event => this.mobileToggleTab(event, 'menu')}>Menu</button>
              <div className="content">
                <div className="left">
                  <HeaderNav active={navActive} className="navigation" closeTabs={this.closeTabs} navItems={navItems} baseUrl={baseUrl} toggleNav={this.toggleNav} />
                  <div className="repeat-tabs">
                    <button onClick={event => this.toggleTab(event, 'markets')}><span>Markets</span></button>
                    <button onClick={event => this.toggleTab(event, 'products')}><span>Products</span></button>
                    <button onClick={event => this.toggleTab(event, 'contact')}><span>Contact</span></button>
                  </div>
                  <div className="locations">
                    {locations.map((location, index) =>
                      <div className="location" key={index}>
                        <span>{location.locationName}</span>
                        <p>{location.addressDisplay}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="right">
                  <Image src={menu_image} />
                </div>
              </div>
            </div>

            <div className={`tab markets${tabActive === 'markets' ? ' active' : ''}`}>
              <button className="mobile-trigger markets" onClick={event => this.mobileToggleTab(event, 'markets')}>Markets</button>
              {tabActive === 'markets' &&
                <div className="content">
                  <div className="full">
                    <div className="desktop-triggers">
                      {allWordpressWpProductMarket.nodes.map((market, index) =>
                        <button key={index} onClick={event => this.toggleSubTab('marketTabs', index)} className={`trigger${marketTabs === index ? ' active' : ''}`} dangerouslySetInnerHTML={{ __html: market.name }} />
                      )}
                    </div>
                    <div className="tabs">
                      {allWordpressWpProductMarket.nodes.map((market, index) =>
                        <div className={`tab${marketTabs === index ? ' active' : ''}`} key={index}>
                          <div className="mobile-trigger" onClick={event => this.toggleSubTab('marketTabs', index)} dangerouslySetInnerHTML={{ __html: market.name }} />
                          <div className="inner">
                            {market.acf && market.acf.menuLinks && market.acf.menuLinks.map((link, index) =>
                              <GatsbyLink key={index} className="action" to={link.link.url} decode={true} onClick={event => this.closeTabs(event)}>{link.link.title}</GatsbyLink>
                            )}
                            {market.acf && market.acf.feature_image && <div className="market-image"><Image src={market.acf.feature_image} /></div>}
                            <RenderContent content={market.acf.menuDescription} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              }
            </div>

            <div className={`tab products${tabActive === 'products' ? ' active' : ''}`}>
              <button className="mobile-trigger products" onClick={event => this.mobileToggleTab(event, 'products')}>Products</button>
              {tabActive === 'products' &&
                <div className="content">
                  <div className="full">
                    <div className="desktop-triggers">
                      {allWordpressWpProductCategory.nodes.map((category, index) => category.slug !== 'gatsby' &&
                        <button key={index} onClick={() => this.toggleSubTab('productTabs', index)} className={`trigger${productTabs === index ? ' active' : ''}`} dangerouslySetInnerHTML={{ __html: category.name }} />
                      )}
                    </div>
                    <div className="tabs">
                      {allWordpressWpProductCategory.nodes.map((category, index) => category.slug !== 'gatsby' &&
                        <div className={`tab${productTabs === index ? ' active' : ''}`} key={index}>
                          <div className="mobile-trigger" onClick={() => this.toggleSubTab('productTabs', index)}>{category.name}</div>
                          {productTabs === index &&
                            <div className="inner">
                              {category.acf.menuLinks && category.acf.menuLinks.map((link, index) =>
                                <GatsbyLink key={index} className="action" to={link.link.url} onClick={event => this.closeTabs(event)}>{link.link.title}</GatsbyLink>
                              )}
                              {!category.acf.menuLinks ?
                                <>
                                  <GatsbyLink to={category.path} onClick={event => this.closeTabs(event)} className="action">View {decodeEntities(category.name)} Overview</GatsbyLink>
                                  <GatsbyLink to={`/resources/joiners-assembly-accessories/`} onClick={event => this.closeTabs(event)} className="action">View Joiners & Assembly Accessories</GatsbyLink>
                                </>
                                : null}
                              <div className="group-title" dangerouslySetInnerHTML={{ __html: category.name }} />
                              <div className="product-list">
                                {productsInThiscategory(category.wordpress_id).map((product, index) =>
                                  <GatsbyLink to={product.path} onClick={event => this.closeTabs(event)} className="product" key={index}>
                                    {product.acf.featureImage ?
                                      <div className="feature-image"><Image src={product.acf.featureImage} position="relative" /></div>
                                      :
                                      <ProductImage above={product.acf.above_honeycomb} below={product.acf.below_honeycomb} />
                                    }

                                    <div className="title" dangerouslySetInnerHTML={{ __html: product.title }} />
                                    <span className="link">View Product</span>
                                  </GatsbyLink>
                                )}
                              </div>

                            </div>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              }
            </div>

            <div className={`tab contact${tabActive === 'contact' ? ' active' : ''}`}>
              <button className="mobile-trigger contact" onClick={event => this.mobileToggleTab(event, 'contact')}>Contact</button>
              {tabActive === 'contact' &&
                <div className="content">

                  <div className="left">
                    <div className="desktop-triggers">
                      {contactTabsItems.map((contact, index) =>
                        <button key={index} onClick={event => this.toggleSubTab('contactTabs', index)} className={`trigger${contactTabs === index ? ' active' : ''}`} dangerouslySetInnerHTML={{ __html: contact }} />
                      )}
                    </div>
                    <div className="tabs">
                      <div className={`tab${contactTabs === 0 ? ' active' : ''}`}>
                        <div className="mobile-trigger">Enquiry</div>
                        <RenderShortcode content={contact_tab_content} />

                        <div className="image">
                          <Image src={contact_tab_image} />
                          <div className="over-text">
                            <span className="description">MANUFACTURING</span>
                            <span className="instruction">View our Our manufacture sites</span>
                            <GatsbyLink className="action" onClick={event => this.closeTabs(event)} to="/contact-us/">View locations</GatsbyLink>
                          </div>
                        </div>
                      </div>
                      <div className={`tab${contactTabs === 1 ? ' active' : ''}`}>
                        <RenderShortcode content={request_a_sample_content} />
                        <div className="mobile-trigger">Request a Sample</div>
                        <div className="image">
                          <Image src={request_a_sample_image} />
                          <div className="over-text">
                            <GatsbyLink className="action" onClick={event => this.closeTabs(event)} to="/request-a-sample/">Request a sample</GatsbyLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>

          </div>
        </div>
      </header>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        abovePrimaryNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "above-primary-navigation" }) {
          items {
            title
            url
            classes
            object_id
            slug: object_slug
            target
            children: wordpress_children {
              title
              url
              classes
              object_id
              slug: object_slug
              target
            }
          }
        }
        primaryNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "primary-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
            object_id
            target
            children: wordpress_children {
              title
              url
              classes
              object_id
              slug: object_slug
              target
            }
          }
        }
        allWordpressWpProductCategory(filter: {slug: {ne: "uncategorised"}}, sort: {order: ASC, fields: menu_order}) {
          nodes {
            name
            slug
            path
            wordpress_id
            description
            acf {
              menuLinks {
                link {
                  url 
                  title
                  target
                }
              }
            }
          }
        }
        allWordpressWpProductMarket {
          nodes {
            path
            acf {
              menuDescription
              menuLinks {
                link {
                  url 
                  title
                  target
                }
              }
              feature_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 670, quality: 70) {
                      ... GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
            name
          }
        }
        allWordpressWpProducts(sort: {order: ASC, fields: menu_order}) {
          nodes {
            product_category {
              wordpress_id
            }
            title
            path
            slug
            acf {
              featureImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 670, quality: 70) {
                      ... GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              below_honeycomb {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 670, quality: 70) {
                        ... GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
              above_honeycomb {
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 670, quality: 70) {
                        ... GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              locationName
              addressDisplay
              email
              phone
            }
            menu_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 670, quality: 70) {
                    ... GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            request_a_sample_content
            request_a_sample_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 670, quality: 70) {
                    ... GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            contact_tab_content
            contact_tab_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 670, quality: 70) {
                    ... GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
        wordpressWpSettings {
          siteUrl
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
