import React from 'react';
import Helmet from 'react-helmet';
import Footer from '../components/Footer';
import '../sass/global/styles.scss';
import Header from '../components/Header';
import { Logo } from '../../theme/Logo';
const Layout = ({ children, location, wordpressUrl }) => (
  <div id="layout">
    <Header location={location} />
    <div className="page">
      <div className="logo-banner">
        <div className="wrap">
          <div className="logo"><Logo/></div>
        </div>
      </div>
      <>{children}</>
      <Footer location={location} />
    </div>
  </div>
)

export default Layout
