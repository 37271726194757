import React, { Component } from 'react';
import { decodeEntities } from '../../utils/helpers';

export default class RenderContent extends Component {
  state = {
    readMore: false,
  };

  toggleReadMore = (event) => {
    event.preventDefault();
    const { readMore } = this.state
    this.setState({readMore: readMore ? false : true})
  }


  render() {
    const { content = '', className = "entry-content"} = this.props;
    const { readMore } = this.state
    const contentSplit = content.split('<p><!--more--></p>');
    var render_content = contentSplit[0] ? contentSplit[0] : content
    var renderReadMoreContent = contentSplit[1] ? contentSplit[1] : null
    var new_content = render_content ? render_content.replace('<iframe', '<div class="outer-video"><div class="video"><iframe') : '';
    var new_content = new_content ? new_content.replace('</iframe>', '</iframe></div></div>') : '';
    return (
      <>
      <div
          className={className}
          dangerouslySetInnerHTML={{__html: decodeEntities(new_content)}}
      />
      {renderReadMoreContent &&
        <div className={`read-more${readMore ? ' show' : ' hide'}`}>
          <div 
            className="read-more-content" 
            dangerouslySetInnerHTML={{__html: decodeEntities(renderReadMoreContent)}}
          />  
          <button className="mobile-read-more" onClick={e => this.toggleReadMore(e)}>{readMore ? 'Read Less' : 'Read More'}</button>
        </div>
      }
      </>
    );
  }
}
