import React from 'react';
import { Image } from '../../Image'
import './ProductImage.scss'

export const ProductImage = (props) => {
  const { above, below } = props
  if (!above[0] || !below[0]) return null
  const reverseBefore = [...above].reverse()
  return (
    <div className="product-image">
      <div className="above">
      {reverseBefore && reverseBefore.map((layer,index) =>
        <div className="layer" key={index} data-order={index}><Image src={layer.image} position="relative"/></div>
      )}
      </div>
      <div className="base"><img src="/images/product-base.svg"/></div>
      <div className="below">
      {below && below.map((layer,index) =>
        <div className="layer" key={index} data-order={index}><Image src={layer.image} position="relative"/></div>
      )}
      </div>
    </div>
  )
}
