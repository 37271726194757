import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Logo } from '../../../theme/LogoFooter';
import { Image } from '../Image'
import GatsbyLink from '../GatsbyLink';
import RenderContent from '../RenderContent';
import './Footer.scss';
import { decodeEntities } from '../../utils/helpers';
const isClient = typeof window !== 'undefined';
import loadable from '@loadable/component'

const CookieNotice = (props) => {
  const { privacyCookiePolicyNotice, privacyCookiePolicyNoticeText } = props.options
  if (privacyCookiePolicyNotice) {
    const CookiePolicy = loadable(() => import(`../CookiePolicy/index.js`))
    return <CookiePolicy content={privacyCookiePolicyNoticeText}/>
  }
  return null
}

export const sendEmail = (event, email) => {
  event.preventDefault()
  window.location = `mailto:${decodeEntities(email)}`;
}

const Footer = (props) => {
  const { data, location = {} } = props;
  const {
    legalNavigation,
    footerNavigation,
    siteSettings,
    wordpressWpSettings
  } = data;
  const { items: legalNav } = legalNavigation;
  const { items: footerNav } = footerNavigation
  const { footerTagline, navigationInFooter, contactDetailsInFooter, privacyCookiePolicyNotice, privacyCookiePolicyNoticeText, locations, certification } = siteSettings.options
  const {
    email,
    phone,
    addressDisplay
  } = siteSettings.options.locations[0];
  const currentYear = (new Date()).getFullYear();

  return (
    <>
    <footer>
      <div className="footer-main">
        <div className="inner">
          <div className="wrap">

            <div className="logos">
              <Logo />
            </div>

            {navigationInFooter &&
              <ul className="footer-nav">
              {footerNav  && footerNav.map((item, index) => (
                <li key={index}><GatsbyLink to={item.url} aria-label={item.title} decode={true}>{item.title}</GatsbyLink></li>
              ))
              }
              </ul>
            }

            <div className="locations">
              {locations.map((location, index) =>
                <div className="location" key={index}>
                  <span>{location.locationName}</span>
                  <p>{location.addressDisplay}</p>
                </div>
              )}
            </div>

            <div className="certification">
            {certification && certification.map((certificate, index) =>
              <div key={index}><Image src={certificate.cert}/></div>
            )}
            </div>


          </div>
        </div>
      </div>

      <div className="disclaimers">
         <div className="wrap">
             {legalNav &&
               <div className="navigation">
                 <ul>
                   {legalNav.map((item, index) => (
                     <li key={index}><GatsbyLink to={item.url} decode={true}>{item.title}</GatsbyLink></li>
                   ))}
                 </ul>
               </div>
             }

             <div className="copyright">
               <span>©{currentYear} <strong>{wordpressWpSettings.title}</strong>, All rights reserved</span>
             </div>
         </div>
       </div>
    </footer>
    <CookieNotice options={siteSettings.options}/>
    </>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "legal-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        },
        footerNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "footer-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
            }
          }
        },
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              locationName
              addressDisplay
            }
            footerTagline
            socialLinksFooter
            navigationInFooter
            contactDetailsInFooter
            privacyCookiePolicyNotice
            privacyCookiePolicyNoticeText
            certification {
              cert {
                localFile {
                  childImageSharp {
                    fixed(width: 55) {
                      ... GatsbyImageSharpFixed_noBase64
                    }
                  }
                }
              }
            }
          }
        },
        wordpressWpSettings {
          title
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
